<template>
  <div>

    <b-overlay
      :show="$apollo.loading || mutationLoading"
      rounded="sm"
    >
      <b-card>
        <!-- search input -->
        <div class="custom-search d-flex justify-content-between align-items-end">
          <b-form-group
            class="form-inline"
            label="Search"
            label-size="sm"
          >
            <b-form-input
              v-model="searchTerm"
              class="d-inline-block mr-1"
              placeholder="Search Competitions"
              type="text"
            />
            <b-button
              :to="{name: 'add-competition'}"
              variant="primary mr-1"
            >
              <feather-icon icon="PlusIcon" />
              Add Competition
            </b-button>
          </b-form-group>
          <b-form-group
            label="View as"
            label-for="btn-radios-1"
            label-size="sm"
          >
            <b-form-radio-group
              id="btn-radios-1"
              v-model="view"
              :options="[
                { text: 'Grid', value: 'grid' },
                { text: 'Table', value: 'table' },
              ]"
              buttons
              size="sm"
              button-variant="light"
            />
          </b-form-group>
        </div>
        <!-- cards -->
        <section
          v-if="view === 'grid'"
          class="grid-view mt-1"
        >
          <b-row>
            <b-col
              v-for="(row, i) in rows"
              :key="i"
              :title="row.title"
              md="4"
              style="margin-bottom: 10px"
            >
              <b-card
                :key="i"
                header-tag="header"
                tag="article"
                class="ecommerce-card border border-secondary"
                no-body
              >
                <Promised
                  v-if="row.image && row.image !== 'null'"
                  :promise="getLinkFromS3(row.image)"
                >
                  <template #pending>
                    <b-card-img
                      class="card-img"
                      src="https://picsum.photos/600/300"
                    />
                  </template>
                  <template #default="data">
                    <b-aspect
                      :style="`background: url(${data}) center center / cover no-repeat`"
                      aspect="2"
                    />
                  </template>
                  <template #rejected>
                    <b-card-img
                      class="card-img"
                      src="https://picsum.photos/600/300"
                    />
                  </template>
                </Promised>
                <b-card-img
                  v-else
                  class="card-img"
                  src="https://picsum.photos/600/300"
                />
                <b-card-body>
                  <h6 class="text-capitalize">
                    {{ row.type }}
                  </h6>
                  <b-card-title>
                    <div class="d-flex justify-content-between">
                      <span>
                        {{ row.title }}
                      </span>
                      <b-link
                        :to="{name: 'view-competitions', params: {id: row.id}}"
                      >
                        <b-button
                          class="ml-2"
                          size="sm"
                          variant="primary"
                          @click.stop="viewProgram(row.id)"
                        >
                          View
                        </b-button>
                      </b-link>
                    </div>
                  </b-card-title>
                  <div class="d-flex align-items-baseline   justify-content-between">
                    <b-card-text>
                      <strong>
                        <feather-icon
                          class="mb-25"
                          icon="CalendarIcon"
                          size="18"
                        />
                        {{ $moment(row.begin_date).format('MMM DD, YYYY') }}</strong></b-card-text>
                    <b-badge class="badge badge-light-success">
                      Online
                    </b-badge>
                  </div>
                  <div class="item-options text-center d-flex justify-content-between ">
                    <b-link
                      :to="{name: 'competitions-application-manager', params: {id: row.id}}"
                      class="btn btn-outline-primary btn-cart"
                      size="sm"
                    >
                      <span>Manage</span>
                    </b-link>
                  </div>
                </b-card-body>
                <b-card-footer
                  class="text-center p-1 pb-0 mt-1"
                >
                  <strong> {{ (row.deliverable)?row.deliverable:"TEST" }} </strong>
                </b-card-footer>
              </b-card>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-between align-items-center  flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLengthGrid"
                :options="['9', '27', '81']"
                class="mx-1"
                @input="updatePageLength"
              />
              <span class="text-nowrap"> of {{ rows.length }} entries </span>
            </div>
            <div>
              <b-pagination
                v-model="currentPage"
                :value="1"
                :per-page="pageLengthGrid"
                :total-rows="totalItems"
                class="mt-1 mb-0"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
                @input="(value) => onPageChange(value)"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </section>
        <!-- table -->
        <vue-good-table
          v-if="view === 'table'"
          :columns="columns"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
          :rows="rows"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'title'">
              {{ props.row.title }}
            </span>
            <span v-else-if="props.column.field === 'begin_date'">
              {{ props.row.begin_date ? formatDate(props.row.begin_date) : 'N/A' }}
            </span>
            <span v-else-if="props.column.field === 'end_date'">
              {{ props.row.end_date ? formatDate(props.row.end_date) : 'N/A' }}
            </span>
            <span
              v-else-if="props.column.field === 'status'"
              class="d-flex justify-content-between align-items-center"
            >
              <b-badge
                :variant="statusVariant(props.row.status)"
                class="border"
              >
                {{ props.row.status }}
              </b-badge>
              <b-dropdown
                no-caret
                size="sm"
                toggle-class="text-decoration-none"
                variant="link"
              >
                <template #button-content>
                  <span class="align-middle text-nowrap">Update Status<feather-icon
                    class="align-middle ml-25"
                    icon="ChevronDownIcon"
                  /></span>
                </template>
                <b-dropdown-item
                  :disabled="props.row.status === 'scheduled'"
                  variant="primary"
                  @click="updateProgramStatus(props.row.id, 'scheduled')"
                >
                  <feather-icon
                    class="mr-50"
                    icon="ClockIcon"
                  />
                  <span>Schedule Program</span>
                </b-dropdown-item>
                <b-dropdown-item
                  :disabled="props.row.status === 'complete'"
                  variant="success"
                  @click="updateProgramStatus(props.row.id, 'complete')"
                >
                  <feather-icon
                    class="mr-50"
                    icon="CheckCircleIcon"
                  />
                  <span>Mark as Complete</span>
                </b-dropdown-item>
                <b-dropdown-item
                  :disabled="props.row.status === 'discontinued'"
                  variant="danger"
                  @click="updateProgramStatus(props.row.id, 'discontinued')"
                >
                  <feather-icon
                    class="mr-50"
                    icon="StopCircleIcon"
                  />
                  <span>Discontinue Program</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
            <!-- Column: Action -->
            <span
              v-else-if="props.column.field === 'action'"
              class="d-flex"
            >
              <b-button
                class="mr-50"
                size="sm"
                variant="outline-primary"
                :to="{name: 'competitions-application-manager', params: {id: props.row.id}}"
              >
                Manager
              </b-button>
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :per-page="pageLength"
                  :total-rows="props.total"
                  :value="1"
                  class="mt-1 mb-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
    </b-overlay>
    <b-modal
      id="scheduleEventModal"
      ok-title="Invite"
      size="lg"
      :title="'Invite '+$route.params.sname+' to Event'"
      @ok="addEvent"
    >
      <b-row>
        <b-col md="12">
          <b-form-group
            class="mb-2"
            label="Select Event"
            label-for="Event"
          >
            <v-select
              id="Event"
              v-model="type"
              :options="meetingTypeOptions"
              placeholder="Select Event"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            class="mb-2"
            label="Message"
            label-for="message"
          >
            <b-form-textarea
              id="message"
              v-model="message"
              placeholder="Enter your message here"
              rows="5"
              trim
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      v-if="rows.length"
      id="updateLinks"
      ok-title="Update"
      title="Update Event Links"
      @ok="updateLinks"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Event Link"
            label-for="updateEventLink"
          >
            <b-form-input
              id="updateEventLink"
              v-model="rows[selectedRow].meeting_link"
              placeholder="Event Link"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Recording Link"
            label-for="updateRecordingLink"
          >
            <b-form-input
              id="updateRecordingLink"
              v-model="rows[selectedRow].recording_link"
              placeholder="Recording Link"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="addParticipants"
      ok-only
      ok-title="Update"
      title="Add Participants"
      @ok="updateParticipants"
    >
      <b-form-group
        label="Select Participant"
        label-for="selectParticipant"
      >
        <treeselect
          id="selectParticipant"
          v-model="participants"
          :options="associations"
          value-consists-of="LEAF_PRIORITY"
          show-count
          show-count-of="LEAF_DESCENDANTS"
          multiple
          placeholder="Select Participant"
        />
      </b-form-group>
    </b-modal>
  </div>
</template>
<script>

import {
  BAspect,
  BBadge,
  BButton,
  BCard,
  BCardFooter,
  BCardImg,
  BCardText,
  BCardTitle,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormSelect,
  BFormTextarea,
  BLink,
  BModal,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
// import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { formatDate } from '@/@core/utils/utils'
import { getUserData } from '@/utils/auth'
import Treeselect from '@riophae/vue-treeselect'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { Promised } from 'vue-promised'

export default {
  components: {
    BDropdownItem,
    BDropdown,
    BAspect,
    BCardImg,
    Promised,
    BFormRadioGroup,
    BBadge,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BModal,
    BCardFooter,
    BCardText,
    BLink,
    BCardTitle,
    BCard,

    BButton,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BFormTextarea,
    vSelect,
    Treeselect,
    // flatPickr,
  },
  data() {
    return {
      title: '',
      pageLengthGrid: 9,
      view: 'grid',
      type: '',
      beginTime: '',
      endTime: '',
      meetingLink: '',
      message: '',
      operationsId: null,
      currentPage: 1,
      operations: [
        {
          id: '1',
          label: 'Milestones',
          children: [],
        },
        {
          id: '2',
          label: 'Requests',
          children: [],
        },
        {
          id: '3',
          label: 'Mentoring',
          children: [],
        },
        {
          id: '4',
          label: 'Partner Services',
          children: [],
        },
        {
          id: '5',
          label: 'General',
          children: [{
            id: '',
            label: 'General',
          }],
        },
      ],
      status: '',
      mutationLoading: false,
      meetingTypeOptions: [],
      searchTerm: '',
      selectedRow: 0,
      pageLength: 5,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Type',
          field: 'type',
        },
        {
          label: 'Begin Date',
          field: 'begin_date',
        },
        {
          label: 'End Date',
          field: 'end_date',
        },
        {
          label: 'Total Applicants',
          field: 'programs_applicantstables_aggregate.aggregate.count',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      selectedOperation: null,
      associations: [
        {
          id: '0',
          label: 'Startups',
          children: [
            {
              id: '0-0',
              label: 'Incubatee',
              children: [],
              isDisabled: true,
            },
            {
              id: '0-1',
              label: 'Graduate',
              children: [],
              isDisabled: true,
            },
            {
              id: '0-2',
              label: 'Network',
              children: [],
              isDisabled: true,
            },
            {
              id: '0-3',
              label: 'Pre-Incubatee',
              children: [],
              isDisabled: true,
            },
          ],
        },
        {
          id: '1',
          label: 'Students',
          children: [
            {
              id: '1-0',
              label: 'In-house',
              children: [],
              isDisabled: true,
            },
            {
              id: '1-1',
              label: 'Network',
              children: [],
              isDisabled: true,
            },
          ],
        },
        {
          id: '2',
          label: 'Mentors',
          children: [
            {
              id: '2-0',
              label: 'In-house',
              children: [],
              isDisabled: true,
            },
            {
              id: '2-1',
              label: 'Network',
              children: [],
              isDisabled: true,
            },
          ],
        },
        {
          id: '3',
          label: 'Partners',
          children: [
            {
              id: '3-0',
              label: 'In-House',
              children: [],
              isDisabled: true,
            },
            {
              id: '3-1',
              label: 'Network',
              children: [],
              isDisabled: true,
            },
          ],
        },
      ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        scheduled: 'light-primary',
        discontinued: 'light-danger',
        draft: 'light-secondary',
        complete: 'light-success',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },

    totalItems() {
      return this.rows.length
    },
    participants: {
      get() {
        return []
        // if (!this.rows[this.selectedRow]) return []
        // return this.rows[this.selectedRow].programs_meetparticipanttables.map(e => e.user_association_id)
      },
      set(e) {
        const participants = []
        // eslint-disable-next-line no-return-assign
        e.forEach((v, i) => participants[i] = {
          user_association_id: v,
          schedule_id_id: this.rows[this.selectedRow].id,
        })
        this.rows[this.selectedRow].programs_meetparticipanttables = participants
      },
    },
    userOrgs() {
      const arr = getUserData()
        .associatedOrgDetails
        .filter(obj => obj.role === 'superadmin')
      return arr.map(e => e.organization_id)
        .filter(obj => obj) // Remove nulls
    },
    milestoneId() {
      if (!this.operationsId) return null
      return this.operationsId.split('-')[0] === 'milestone' ? this.operationsId.split('-')[1] : null
    },
    requestId() {
      if (!this.operationsId) return null
      return this.operationsId.split('-')[0] === 'request' ? this.operationsId.split('-')[1] : null
    },
  },
  methods: {
    formatDate,
    updateProgramStatus(id, status) {
      this.$apollo.mutate({
        mutation: gql`
          mutation UpdateProgramStatus($id: Int!, $status: String!) {
            update_programs_basicinfo_by_pk(pk_columns: {id: $id}, _set: {status: $status}) {
              id
              status
            }
          }`,
        variables: {
          id,
          status,
        },
        update: () => {
          this.showAlert('Program status updated', 'success')
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
    onPageChange(value) {
      this.currentPage = value
    },
    updatePageLength(value) {
      this.pageLengthGrid = value
    },
    toTimestamp(strDate) {
      const datum = Date.parse(strDate)
      return datum / 1000
    },
    addEvent() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
        mutation {
          insert_programs_scheduletable_one(object: {begin_time: "${this.beginTime}", end_time: "${this.endTime}", meeting_link: "${this.meetingLink}", milestone_id: ${this.milestoneId}, request_id: ${this.requestId}, status: "scheduled", title: "${this.title}", type: "${this.type}", programs_meetparticipanttables: {data: {user_association_id: 84}}}) {
            id
          }
        }`,
        update: () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'EMeet created successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to create EMeet',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          this.mutationLoading = false
        })
    },
    updateLinks() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
        mutation {
          update_programs_scheduletable_by_pk(pk_columns: {id: ${this.rows[this.selectedRow].id} }, _set: {recording_link: "${this.rows[this.selectedRow].recording_link}", meeting_link: "${this.rows[this.selectedRow].meeting_link}"}) {
            id
          }
        }`,
        update: (store, { data: { update_programs_scheduletable_by_pk } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: update_programs_scheduletable_by_pk.id ? 'Updated successfully' : 'Failed to update',
              icon: update_programs_scheduletable_by_pk.id ? 'CheckIcon' : 'XIcon',
              variant: update_programs_scheduletable_by_pk.id ? 'success' : 'warning',
            },
          })
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
    updateParticipants() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`mutation MyMutation($objects: [programs_meetparticipanttable_insert_input!]!) {
          delete_programs_meetparticipanttable(where: {schedule_id_id: {_eq: ${this.rows[this.selectedRow].id}}}) {
            affected_rows
          }
          insert_programs_meetparticipanttable(objects: $objects) {
            affected_rows
          }
        }`,
        variables: {
          objects: this.rows[this.selectedRow].programs_meetparticipanttables,
        },
        update: () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Updated successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
  },
  apollo: {
    rows: {
      query() {
        return gql`
          query MyQuery{
          programs_basicinfo(order_by: {date_created: desc}, where: {organization_id_id: {_in: [${this.userOrgs}]}, is_competition: {_eq: true}}) {
            id
            title
            status
            programs_applicantstables_aggregate {
              aggregate {
                count
              }
            }
            type
            begin_date
            capacity
            city
            customer_type
            delivery_mode
            description
            duration
            end_date
            focus_type
            offerings_type
            ideal_lifecycle_stage
            industry
            image
            is_public
            offerings_type
            programs_deliverablelisttables {
              deliverable
            }
            state
          }
        }`
      },
      update: data => {
        data.programs_basicinfo.forEach(e => {
          e.deliverable = e.programs_deliverablelisttables.map(d => d.deliverable).join(' • ')
        })
        return data.programs_basicinfo
      },
    },
    operations: {
      query() {
        return gql`
        query startup_emeet_operations {
          requesttable(where: { _or: [
    { assignee_id: {_eq: ${this.$route.params.id} }},
    { requester_id: {_eq: ${this.$route.params.id} }}]}, order_by: {created_at: desc}) {
            requesttable_id
            title
            type
          }
          programs_progressstagetable(where: { startup_association_id: {_eq: ${this.$route.params.id}}} ,order_by: {stage_number: desc}) {
            id
            title
          }
        }`
      },
      update(data) {
        const { operations } = this
        const types = ['milestones', 'request', 'mentor', 'partner']
        data.programs_progressstagetable.forEach(e => {
          operations[0].children.push({
            label: e.title,
            id: `milestone-${e.id}`,
          })
        })
        data.requesttable.forEach(e => {
          operations[types[e.type]].children.push({
            label: e.title,
            id: `request-${e.requesttable_id}`,
          })
        })
        return operations
      },
    },
    associations: {
      query() {
        return gql`
        query startup_associations($incubator_id: Int!) {
          users_associationtable(where: {incubator_id: {_eq: $incubator_id}}) {
            id
            role
            status
            users_customuser {
              full_name
            }
            users_organizationtable {
              title
            }
          }
        }`
      },
      variables: {
        incubator_id: getUserData()
          .associatedOrgDetails
          .filter(e => e.role === 'superadmin')[0].organization_id,
      },
      update(data) {
        const { associations } = this
        data.users_associationtable.forEach(e => {
          if (e.role === 'partner') {
            associations[3].children[e.status === 'accept' ? 0 : 1].children.push({
              label: e?.users_organizationtable?.title,
              id: e.id,
            })
            associations[3].children[e.status === 'accept' ? 0 : 1].isDisabled = false
          } else if (e.role === 'mentor') {
            associations[2].children[e.status === 'accept' ? 0 : 1].children.push({
              label: e.users_customuser.full_name,
              id: e.id,
            })
            associations[2].children[e.status === 'accept' ? 0 : 1].isDisabled = false
          } else if (e.role === 'student') {
            associations[1].children[e.status === 'accept' ? 0 : 1].children.push({
              label: e.users_customuser.full_name,
              id: e.id,
            })
            associations[1].children[e.status === 'accept' ? 0 : 1].isDisabled = false
          } else if (e.role === 'incubatee') {
            associations[0].children[0].children.push({
              label: e?.users_organizationtable?.title,
              id: e.id,
            })
            associations[0].children[0].isDisabled = false
          } else if (e.role === 'graduate') {
            associations[0].children[1].children.push({
              label: e?.users_organizationtable?.title,
              id: e.id,
            })
            associations[0].children[1].isDisabled = false
          } else if (e.role === 'startup') {
            associations[0].children[2].children.push({
              label: e?.users_organizationtable?.title,
              id: e.id,
            })
            associations[0].children[2].isDisabled = false
          } else if (e.role === 'pre-incubatee') {
            associations[0].children[3].children.push({
              label: e?.users_organizationtable?.title,
              id: e.id,
            })
            associations[0].children[3].isDisabled = false
          }
        })
        return associations
      },
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
